/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

import BackgroundImage from 'gatsby-background-image';

// import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import NoImageAvailable from './no-image-available';

const SlickLeftNav = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <FaArrowAltCircleLeft />
    </div>
  );
};

const SlickRightNav = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <FaArrowAltCircleRight />
    </div>
  );
};

const ContentView = ({ selectedSlide, viewImageModal, viewContent }) => {
  const SliderRef = useRef();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: true,
    nextArrow: <SlickRightNav />,
    prevArrow: <SlickLeftNav />,
  };

  useEffect(() => {
    SliderRef.current.slickGoTo(selectedSlide, true);
  }, [selectedSlide]);

  return (
    <Slider {...settings} ref={SliderRef}>
      {viewContent}
    </Slider>
  );
};

const ContentThumbnailView = ({ onSlideChange, viewContent }) => {
  const settings = {
    dots: false,
    infinite: viewContent.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // centerMode: true,
    focusOnSelect: true,
    /*
    afterChange: (current) => {
      onSlideChange(current);
    },
    */
    nextArrow: <SlickRightNav />,
    prevArrow: <SlickLeftNav />,
  };

  return <Slider {...settings}>{viewContent}</Slider>;
};

const FluidImagePreview = ({
  source,
  height,
  width,
  defHeight,
  defWidth,
  className,
}) => {
  let fluid = '';
  if (source && source.childImageSharp && source.childImageSharp.fluid) {
    fluid = source.childImageSharp.fluid;
    return (
      <BackgroundImage
        fluid={fluid}
        style={{
          height: `${height || defHeight || 400}px`,
          width: `${width || defWidth || 400}px`,
        }}
        className={className}
      ></BackgroundImage>
    );
  }

  return (
    <NoImageAvailable
      height={height || defHeight || 400}
      width={width || defWidth || 400}
      className={`mx-auto ${className}`}
    />
  );
};

const SlickContentGallery = ({ content }) => {
  const [activeThumbs, setActiveThumbs] = useState(0);

  const onThumbChangeIndex = (currentActive) => {
    setActiveThumbs(currentActive);
  };

  const ImagesViewContent = [];
  const ImagesThumbsViewContent = [];
  content.forEach((item, index) => {
    ImagesViewContent.push(
      <FluidImagePreview
        source={item}
        key={`image-view-${index}`}
        className="image-preview"
      />
    );

    ImagesThumbsViewContent.push(
      <a
        href="/#"
        className="w-auto no-outline"
        onClick={(e) => {
          e.preventDefault();
          setActiveThumbs(index);
        }}
        key={`image-thumbs-${index}`}
      >
        <FluidImagePreview
          source={item}
          height={100}
          width={100}
          key={`image-thumbs-${index}`}
          className="thumbs-preview"
        />
      </a>
    );
  });

  return (
    <div>
      <ContentView
        viewContent={ImagesViewContent}
        selectedSlide={activeThumbs}
      />

      <div className="my-2">
        <ContentThumbnailView
          viewContent={ImagesThumbsViewContent}
          onSlideChange={onThumbChangeIndex}
        />
      </div>
    </div>
  );
};

export default SlickContentGallery;

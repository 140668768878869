import React from 'react';

import Product from '../components/content/our-products/product';

const ProductTemplate = ({ pageContext, location }) => {
  return (
    <Product
      product={pageContext.product || []}
      previous={pageContext.previous || ''}
      next={pageContext.next || ''}
      location={location}
    />
  );
};

export default ProductTemplate;

import React from 'react';

import { Link } from 'gatsby';

import Layout from '../../layout/main';
import Head from '../../layout/head';

import Umbrella from '../../../images/umbrella.svg';

import SlickContentGallery from '../../widgets/slick-content-gallery';
import NoImageAvailable from '../../widgets/no-image-available';

const ProductNav = ({
  category,
  categoryLabel,
  subCategory,
  subCategoryLabel,
}) => {
  return (
    <>
      <div className="text-left mb-5">
        <Link
          to="/our-products"
          className="text-black-brown ml-3 text-decoration-none"
        >
          &lt; Back to all categories
        </Link>
      </div>

      <div className="row mb-5">
        <div className="col-6 text-right">
          <Link
            to={`/our-products/${category}`}
            className="text-green-brown ml-3 text-decoration-none fs-20 ganen-green-brown ganen-bold"
          >
            {categoryLabel}
          </Link>
        </div>

        <div className="col-6 text-left p-0 fs-20 ganen-bold">
          <span className="ganen-bold">|</span>

          <Link
            to={`/our-products/${category}/${subCategory}`}
            className="text-green-brown ml-3 text-decoration-none fs-20 ganen-green-brown ganen-bold"
          >
            {subCategoryLabel}
          </Link>
        </div>
      </div>
    </>
  );
};

const Specs = ({ specs }) => {
  return (
    <ul>
      {specs.map((spec) => {
        return <li key={encodeURI(spec)}>{spec}</li>;
      })}
    </ul>
  );
};

const Models = ({ models }) => {
  return (
    <>
      <div className="col-4">Model:</div>
      <div className="col-8">
        {models.map((model) => {
          return <div key={encodeURI(model)}>{model}</div>;
        })}
      </div>
    </>
  );
};

const Colors = ({ colors }) => {
  return (
    <>
      <div className="col-4">Colours available:</div>
      <div className="col-8">
        {colors.map((color) => {
          return <div key={encodeURI(color)}>{color}</div>;
        })}
      </div>
    </>
  );
};

const Product = ({ product, next, previous }) => {
  const {
    category,
    categoryLabel,
    subCategory,
    subCategoryLabel,
    label,
    specs,
    images,
    models,
    colors,
  } = product.node;

  return (
    <Layout>
      <Head title={`${label}`} />

      <div className="box-container my-5 text-center">
        <ProductNav
          category={category}
          categoryLabel={categoryLabel}
          subCategory={subCategory}
          subCategoryLabel={subCategoryLabel}
        />

        <h3 className="fs-35 ganen-green-bold my-5">{label}</h3>

        <div className="box-container col-12 row m-0">
          <div className="col-12 col-md-6 pb-5 ganen-bold px-3">
            {images ? (
              <SlickContentGallery content={images} />
            ) : (
              <NoImageAvailable
                height={400}
                width={451}
                className="mx-auto image-preview"
              />
            )}
          </div>

          <div className="col-12 col-md-6 text-left px-3">
            <div className="pb-3 pb-md-5">
              <Specs specs={specs} />
            </div>

            <div className="row pb-3 pb-md-5">
              <Models models={models} />
            </div>

            <div className="row pb-3 pb-md-5">
              <Colors colors={colors} />
            </div>

            <div className="d-flex">
              <span>
                <Umbrella height={40} className="mr-5 mb-5" color="#a57e48" />
              </span>
              <span className="mw-300 d-inline-block">
                Our Umbrella Programme is a 5-year warranty coverage on all your
                GANEN products that includes on-site servicing and replacements,
                giving you a worry-free experience with your ideal home
                automation solution.
                <br />
                <br />
                <Link
                  to="/contact-us"
                  className="text-sky-blue text-decoration-none"
                >
                  Contact us
                </Link>{' '}
                to find out more.
              </span>
            </div>
          </div>
        </div>

        <div className="box-container col-12 row m-0 mt-5">
          <div className="col-6 text-left p-0">
            <Link
              to={`${previous}`}
              className="text-decoration-none text-black p-2"
            >
              &lt; Previous Item
            </Link>
          </div>
          <div className="col-6 text-right p-0">
            <Link
              to={`${next}`}
              className="text-decoration-none text-black p-2"
            >
              Next Item &gt;
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;

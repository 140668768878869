import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const NoImageAvailable = ({ height, width, className }) => {
  const { noImage } = useStaticQuery(graphql`
    query {
      noImage: file(relativePath: { eq: "no-image-available.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      fluid={noImage.childImageSharp.fluid}
      style={{ height: height || '400px', width: width || '400px' }}
      className={className || ''}
    ></BackgroundImage>
  );
};

export default NoImageAvailable;
